.page-container {
    display: flex;
    background-color:#1B2646; 
    width:100%;
  }
  
  .left-div {
    flex: 1;
    padding: 20px;
    font-family: sans-serif;
    position: relative;
  }
  
  .right-div {
    flex: 1;
    position: relative;
    align-content: center;
  }
  
  img {
    max-width: 100%;
    height: auto;
    display: flexbox;
    align-content: center;
  }
  